export enum NewVisitField {
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE = 'phone',
}

export const EMPTY_VISIT = {
  [NewVisitField.FIRSTNAME]: '',
  [NewVisitField.LASTNAME]: '',
  [NewVisitField.PHONE]: '',
};

export const NewVisitFormConfig = [
  { label: 'Имя', field: NewVisitField.FIRSTNAME },
  { label: 'Фамилия', field: NewVisitField.LASTNAME },
  { label: 'Телефон', field: NewVisitField.PHONE },
];
