import baseAxios from 'axios';
import type { IErrorResponse } from '@app/interfaces/errorResponse.interface';
import { API_RESPONSE_STATUS_CODE, BASE_URL } from '@app/constants/common';

import globalAppStore from '@app/stores/globalAppStore';
import type { IVisit } from '@app/interfaces/visit.interface';
import { IOperator } from '@app/interfaces/operator.interface';
import { IIdentReception } from '@app/interfaces/ident.reception.interface';
import { Dayjs } from 'dayjs';

const api = baseAxios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (options) => {
    const token = globalAppStore.token;
    if (token) {
      options.headers!.Authorization = `Bearer ${token}`;
    }
    return options;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = (error?.response as IErrorResponse) || {};

    if (status === API_RESPONSE_STATUS_CODE.UNAUTHORIZED) {
      globalAppStore.logOut();
    }

    return Promise.reject(error);
  }
);

export const doGet = (url: string, options: any) => api.get(url, options);
export const doPost = (url: string, options: any) => api.post(url, options);
export const doPut = (url: string, options: any) => api.put(url, options);
export const doPatch = (url: string, options: any) => api.patch(url, options);
export const doDelete = (url: string, options: any) => api.delete(url, options);

export const fetchVisitBySlug = async (slug: string) => {
  try {
    const result = await doGet(`/api/visit/${slug}`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchVisitInfoBySlug = async (slug: string) => {
  try {
    const result = await doGet(`/api/visit/${slug}/info`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchFeedbacks = async (
  clinicId: number,
  limit: number,
  offset: number
) => {
  try {
    const result = await doGet(`/api/feedbacks`, {
      params: { clinicId, limit, offset },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateFeedback = async (
  feedbackId: number,
  isFeedbackReceived: boolean
) => {
  try {
    const result = await doPost(`/api/feedbacks/${feedbackId}`, {
      isFeedbackReceived,
    });
    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createFeedback = async (
  slug: string,
  rating: number,
  text: string
) => {
  try {
    const result = await doPost(`/api/visit/${slug}/feedback`, {
      rating,
      text,
    });
    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const sendSms = async (visit: IVisit) => {
  try {
    const result = await doPost(`/api/visit/${visit.slug}/sms`, {});

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const toggleFeedbackSite = async (
  clinicId: number,
  feedbackSiteName: string
) => {
  try {
    const result = await doPatch(`/api/clinics/${clinicId}/feedback-site`, {
      feedbackSiteName,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const saveReceptions = async (
  date: Dayjs,
  clinicId: number,
  operator: IOperator,
  receptions: IIdentReception[]
) => {
  try {
    const result = await doPost(`/api/clinics/${clinicId}/receptions`, {
      date,
      operator,
      receptions,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export default api;
