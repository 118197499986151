import React from 'react';
import { Container } from '@mui/material';

function AdminLoginLayout({ children }: { children: React.ReactElement }) {
  return (
    <Container maxWidth={false} sx={{ background: 'whitesmoke' }}>
      {children}
    </Container>
  );
}

export default AdminLoginLayout;
