import { observer } from 'mobx-react-lite';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import globalAppStore from '@app/stores/globalAppStore';

const GlobalConfirmPopup = observer(() => {
  const popupState = globalAppStore.globalConfirmPopupState;

  const handleClose = () => globalAppStore.closeGlobalConfirmPopup();

  const handleConfirm = () => {
    globalAppStore.globalConfirmPopupState.onConfirm();
    handleClose();
  };

  return (
    <Dialog
      maxWidth='xs'
      open={popupState.isVisible}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>{popupState.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{popupState.text}</DialogContentText>
        {popupState.subtext ? (
          <DialogContentText>{popupState.subtext}</DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button color='error' variant='contained' onClick={handleConfirm}>
          {popupState.confirmButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default GlobalConfirmPopup;
