export const APP_LOCALSTORAGE_KEY = 'respect_medicine_app';

export const APP_LOCALSTORAGE_JWT_KEY = 'jwt_token';

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : 'https://sv.respect-m.ru';

export const FEEDBACK_URL = 'https://fb.respect-m.ru';

export enum AppRoute {
  LOGIN = '/admin/login',
  CLINICS = '/admin/clinics',
  CLINIC_ID = '/admin/clinics/:clinicId',
  VISITS = '/admin/visits',
  ADMIN_FEEDBACKS = '/admin/feedbacks',
  IDENT_RECEPTIONS = '/admin/ident/receptions',
  HOME = '/',
  FEEDBACK = '/feedback/:slug',
  FEEDBACK_SUCCESS = '/feedback/:slug/success',
  FEEDBACK_FAIL = '/feedback/:slug/fail',
  FEEDBACK_SUBMIT = '/feedback/:slug/submit',
}

export const API_RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
};

export const USER_ROLE = {
  ADMIN: 'admin',
  OPERATOR: 'operator',
};

export const PHONE_MAX_NUMBERS = 11;
