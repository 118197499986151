import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddVisitButton from '@app/pages/visitsPage/components/AddVisitButton';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MoreIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import globalAppStore from '@app/stores/globalAppStore';
import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { doDelete } from '@app/api';
import { sendCountedSms } from '@app/api/visit';
import { fetchClinicVisit } from '@app/utils/clinic';
import type { IVisit } from '@app/interfaces/visit.interface';
import { API_RESPONSE_STATUS_CODE, FEEDBACK_URL } from '@app/constants/common';

dayjs.locale('ru');

const store = new VisitsPageStore();

type AnchorsListType = HTMLElement | null;

const VisitsPage = observer(() => {
  const [isLoadingId, setIsLoadingId] = React.useState<number | null>(null);
  const [isDeletingId, setIsDeletingId] = React.useState<number | null>(null);
  const [date, setDate] = React.useState<Dayjs>(() => dayjs());
  const [anchorsList, setAnchorsList] = React.useState<AnchorsListType[] | []>(
    []
  );

  React.useEffect(() => {
    if (globalAppStore.loggedUser?.clinic.id) {
      fetchClinicVisit(globalAppStore.loggedUser?.clinic.id, date).then(
        (visits) => store.setVisits(visits)
      );
    }
  }, [date]);

  const handleMenu =
    (idx: number) => (event: React.MouseEvent<HTMLElement>) => {
      const updatedAnchorsList = [...anchorsList];
      updatedAnchorsList[idx] = event.currentTarget;
      setAnchorsList(updatedAnchorsList);
    };

  const handleClose = (idx: number) => () => {
    if (idx === -1) {
      closeAll();
    } else {
      const updatedAnchorsList = [...anchorsList];
      updatedAnchorsList[idx] = null;
      setAnchorsList(updatedAnchorsList);
    }
  };

  const closeAll = () => setAnchorsList([]);

  const editVisit = (visit: IVisit) => {
    closeAll();
    store.setVisit(visit);
    store.openEditForm();
  };

  const deleteVisit = async (visit: IVisit) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление визита',
      text: 'Вы уверены, что хотите удалить Визит?',
      subtext: `ФИО: ${getFullName(visit)}, ${getPhone(visit)}`,
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(visit.id);
        const result = await doDelete(`/api/visit/${visit.slug}`, {});

        if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
          store.deleteVisit(visit);
          closeAll();
        }
        setIsDeletingId(null);
      },
    });
  };

  const handleSendSms = async (visit: IVisit) => {
    setIsLoadingId(visit.id);
    const updatedVisit = await sendCountedSms(visit);

    if (updatedVisit) {
      store.updateVisit(updatedVisit);
    }

    setIsLoadingId(null);
    closeAll();
  };

  return (
    <>
      <PageTitle
        title=''
        action={
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <DatePicker
              value={date}
              onChange={(val) => setDate(val as Dayjs)}
              format='DD MMMM YYYY'
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{ backgroundColor: 'white' }}
            />

            <AddVisitButton store={store} date={date} />
          </Box>
        }
      />

      <Paper
        sx={{ borderRadius: '30px', padding: '5px 40px 20px', mb: 5 }}
        variant='outlined'
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {store.visits.map((visit, idx: number) => (
            <ListItem
              key={visit.id}
              sx={{
                backgroundColor: !!visit.receptionId ? 'aliceblue' : 'inherit',
              }}
              divider
              dense
            >
              <ListItemIcon
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '25px',
                  fontWeight: 600,
                  color: '#5A5A5A',
                }}
              >
                {idx + 1}
              </ListItemIcon>
              <ListItemText
                primary={getFullName(visit)}
                secondary={getPhone(visit)}
                primaryTypographyProps={{
                  fontFamily: 'Raleway',
                  fontSize: '22px',
                  fontWeight: 600,
                }}
                secondaryTypographyProps={{
                  fontFamily: 'Raleway',
                  fontSize: '20px',
                  fontWeight: 600,
                  color: '#5A5A5A',
                }}
              />
              <ListItemSecondaryAction sx={{ display: 'flex', right: 0 }}>
                {isLoadingId === visit.id ? (
                  <CircularProgress size={32} />
                ) : (
                  globalAppStore.loggedUser?.clinic
                    .isOperatorPermittedToSendSms && (
                    <IconButton
                      aria-label='sms'
                      size='large'
                      color={visit.isSmsSent ? 'default' : 'primary'}
                      disabled={visit.isSmsSent}
                      onClick={() => handleSendSms(visit)}
                    >
                      <SmsIcon />
                    </IconButton>
                  )
                )}

                <div>
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu(idx)}
                    color='primary'
                  >
                    <MoreIcon />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorsList?.[idx] || null}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorsList?.[idx])}
                    onClose={handleClose(-1)}
                  >
                    <MenuItem onClick={() => editVisit(visit)}>
                      <ListItemIcon>
                        <EditIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      <ListItemText>Редактировать</ListItemText>
                    </MenuItem>

                    {globalAppStore.loggedUser?.clinic
                      .isOperatorPermittedToSendSms && (
                      <div>
                        <Divider />

                        <MenuItem
                          onClick={() => handleSendSms(visit)}
                          disabled={isLoadingId === visit.id}
                        >
                          <ListItemIcon>
                            {isLoadingId === visit.id ? (
                              <CircularProgress size={16} />
                            ) : (
                              <SmsIcon fontSize='small' color='primary' />
                            )}
                          </ListItemIcon>
                          <ListItemText>Отправить СМС</ListItemText>
                        </MenuItem>

                        <CopyToClipboard
                          text={`${FEEDBACK_URL}/${visit.slug}`}
                          onCopy={closeAll}
                        >
                          <MenuItem>
                            <ListItemIcon>
                              <InsertLinkIcon
                                fontSize='small'
                                color='primary'
                              />
                            </ListItemIcon>
                            <ListItemText>Скопировать ссылку</ListItemText>
                          </MenuItem>
                        </CopyToClipboard>
                      </div>
                    )}

                    <Divider />

                    <MenuItem
                      onClick={() => deleteVisit(visit)}
                      disabled={isDeletingId === visit.id}
                    >
                      <ListItemIcon>
                        {isDeletingId === visit.id ? (
                          <CircularProgress size={16} color='error' />
                        ) : (
                          <DeleteIcon fontSize='small' color='error' />
                        )}
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ color: 'error' }}>
                        Удалить
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
});

const getFullName = (visit: IVisit) => {
  const { firstname, lastname } =
    visit.firstname && visit.lastname
      ? { firstname: visit.firstname, lastname: visit.lastname }
      : {
          firstname: visit.client.firstname,
          lastname: visit.client.lastname,
        };

  return `${lastname} ${firstname}`;
};

const getPhone = (visit: IVisit) =>
  `тел.: ${visit.phone ? visit.phone : visit.client.phone}`;

export default VisitsPage;
