import React from 'react';
import { Container } from '@mui/material';
import Header from '@app/layouts/admin/components/header/Header';
import bgPart from '@app/layouts/feedback/bg-part.png';

interface MainPageLayoutProps {
  children: React.ReactElement;
  className?: string;
}

function AdminLayout({ children }: MainPageLayoutProps) {
  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          backgroundImage: `url(${bgPart})`,
          padding: 0,
          minHeight: '100vh',
        }}
      >
        <Header />
        <Container
          maxWidth='xl'
          sx={{
            marginTop: '24px',
            height: '100%',
            display: 'grid',
            gap: '20px',
          }}
        >
          {children}
        </Container>
      </Container>
    </>
  );
}

export default AdminLayout;
