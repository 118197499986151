import * as React from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as LogoIcon } from '../../../../assets/images/logo.svg';
import { ReactComponent as UseIcon } from '../../../../assets/images/user.svg';

import globalAppStore from '@app/stores/globalAppStore';

import './header.css';
import { observer } from 'mobx-react-lite';
import { AppRoute } from '@app/constants/common';

const linksConfig = [
  { link: AppRoute.IDENT_RECEPTIONS, title: 'IDENT' },
  { link: AppRoute.CLINICS, title: 'Клиники' },
  { link: AppRoute.ADMIN_FEEDBACKS, title: 'Отзывы' },
  { link: AppRoute.VISITS, title: 'Визиты' },
];

const Header = observer(() => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position='sticky'>
      <Container maxWidth='xl'>
        <Toolbar
          disableGutters
          sx={{ width: '100%', minHeight: { md: '66px' } }}
        >
          <LogoIcon />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography
              variant='h2'
              sx={{
                fontFamily: 'Raleway',
                fontSize: '30px',
                fontWeight: 600,
                marginRight: 2,
              }}
            >
              {globalAppStore.loggedUser?.clinic?.name}
            </Typography>

            <Typography
              variant='h3'
              sx={{ fontFamily: 'Raleway', fontSize: '20px', fontWeight: 600 }}
            >
              {globalAppStore.loggedUser?.clinic?.address}
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'grid', md: 'none' },
              gridAutoFlow: 'column',
              justifyContent: 'start',
              gap: '16px',
            }}
          >
            {globalAppStore.isAdmin
              ? linksConfig.map(({ link, title }) => (
                  <NavLink key={link} to={link} className='menu-item'>
                    {title}
                  </NavLink>
                ))
              : null}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Меню администратора'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <UseIcon />

                <Typography
                  variant='h6'
                  noWrap
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontSize: '20px',
                    fontWeight: 600,
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  {globalAppStore.loggedUser?.name}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={globalAppStore.logOut}>
                <Typography textAlign='center'>Выйти</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default Header;
