import React from 'react';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import globalAppStore from '@app/stores/globalAppStore';
import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { doPost } from '@app/api';
import { fetchDoctorsByClinic } from '@app/api/doctor';
import type { IDoctor } from '@app/interfaces/doctor.interface';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { PHONE_MAX_NUMBERS } from '@app/constants/common';
import { NewVisitField } from '@app/constants/visit';

type NewVisitPropType = {
  store: VisitsPageStore;
  date: Dayjs;
};

const AddVisitButton = observer(({ store, date }: NewVisitPropType) => {
  const [doctors, setDoctors] = React.useState<IDoctor[]>();
  const [selectedDoctor, setSelectedDoctor] = React.useState<IDoctor>();
  const [isLoading, setIsLoading] = React.useState(false);

  const clinic = globalAppStore.loggedUser!.clinic;

  React.useLayoutEffect(() => {
    if (store.isEditVisitFormOpen) {
      store.setFirstname('');
      store.setLastname('');
      store.setPhone('');
    }
  }, [store, store.isEditVisitFormOpen]);

  React.useEffect(() => {
    if (store.isEditVisitFormOpen) {
      setSelectedDoctor(undefined);
    }
  }, [store.isEditVisitFormOpen]);

  React.useEffect(() => {
    if (store.isEditVisitFormOpen && clinic?.isProdoctorovDoctorFeedback) {
      fetchDoctorsByClinic(clinic.id).then((data: IDoctor[]) => {
        setDoctors(data);

        if (store?.visit?.doctorid) {
          const doctor = data.find((d) => d.id === store.visit?.doctorid);
          setSelectedDoctor(doctor);
        }
      });
    }
  }, [
    store.isEditVisitFormOpen,
    clinic?.id,
    clinic?.isProdoctorovDoctorFeedback,
    store?.visit?.doctorid,
  ]);

  const firstname =
    store.firstname ||
    store?.visit?.firstname ||
    store?.visit?.client?.firstname ||
    '';

  const lastname =
    store.lastname ||
    store?.visit?.lastname ||
    store?.visit?.client?.lastname ||
    '';

  const phone =
    store.phone || store?.visit?.phone || store?.visit?.client?.phone || '';

  const addVisit = async () => {
    const clinicId = globalAppStore.loggedUser?.clinic?.id;
    const currentOperator = globalAppStore.loggedUser;

    if (!clinicId || !currentOperator) {
      return;
    }

    const result = await doPost(`/api/clinics/${clinicId}/visit`, {
      visit: {
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        phone: getPhone(phone),
        doctorid: selectedDoctor?.id || 0,
        visitedAt: date.format('DD.MM.YYYY'),
      },
      operator: currentOperator,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      store.addVisit(result.data);
      store.clearData();
      handleClose();
    }
  };

  const updateVisit = async () => {
    if (!store.visit) {
      return;
    }

    setIsLoading(true);

    const result = await doPost(`/api/visit/${store.visit.slug}`, {
      firstname,
      lastname,
      phone: getPhone(phone),
      doctorid: selectedDoctor?.id || 0,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      store.updateVisit(result.data);
      store.clearData();
      store.setIsEditVisitFormOpen(false);
    }

    setIsLoading(false);
  };

  const selectDoctor = (e: SelectChangeEvent) => {
    if (!doctors?.length) {
      return;
    }

    const doctor = doctors.find((doctor) => {
      return doctor.id.toString() === e.target.value.toString();
    });

    setSelectedDoctor(doctor);
  };

  const handleOpen = () => {
    store.setVisit(null);
    store.openEditForm();
  };

  const handleClose = () => store.closeEditForm();

  const isValidForm = () => firstname && lastname && isValidPhone(phone);

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleOpen}
        size='medium'
        sx={{ backgroundColor: 'white' }}
      >
        Добавить пациента
      </Button>

      <Dialog
        open={store.isEditVisitFormOpen}
        onClose={handleClose}
        sx={{}}
        maxWidth='xs'
        PaperProps={{
          sx: {
            borderRadius: '20px',
            border: '4px solid #98B6F1',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            pb: 1,
          }}
        >
          Данные пациента
        </DialogTitle>
        <DialogContent>
          <StyledTextField
            autoFocus={true}
            margin='dense'
            id={NewVisitField.FIRSTNAME}
            label='Имя'
            fullWidth
            value={firstname}
            onChange={(e) => store.setFirstname(e.target.value)}
          />

          <StyledTextField
            margin='dense'
            id={NewVisitField.LASTNAME}
            label='Фамилия'
            fullWidth
            value={lastname}
            onChange={(e) => store.setLastname(e.target.value)}
          />

          <FormControl variant='outlined' fullWidth sx={{ marginTop: '8px' }}>
            <InputLabel htmlFor='phone'>Телефон</InputLabel>
            <InputMask
              id='phone'
              mask='+7 999 999 9999'
              value={phone}
              onChange={(e) => store.setPhone(e.target.value)}
            >
              <StyledOutlinedInput type='tel' label='Телефон' />
            </InputMask>
          </FormControl>

          {clinic?.isProdoctorovDoctorFeedback && doctors?.length && (
            <FormControl fullWidth sx={{ width: '100%', marginTop: '12px' }}>
              <InputLabel id='doctor-select'>Доктор</InputLabel>
              <Select
                labelId='doctor-select'
                id='doctor'
                label='Доктор'
                value={(selectedDoctor?.id || '').toString()}
                onChange={selectDoctor}
              >
                {doctors.map((doctor) => (
                  <MenuItem key={doctor.id} value={doctor.id} divider dense>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{doctor.fio}</Typography>
                      <Typography variant='caption' color='gray'>
                        {doctor.profession}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <SubmitButton
            onClick={store.visit?.id ? updateVisit : addVisit}
            disabled={!isValidForm() || isLoading}
            variant='contained'
            fullWidth
            size='large'
            sx={{}}
          >
            {store.visit?.id ? 'Сохранить' : 'Отправить'}
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
const getPhone = (phone: string): string => phone.replace(/\D/g, '');

const isValidPhone = (phone: string): boolean =>
  getPhone(phone).length === PHONE_MAX_NUMBERS;

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '24px',
    '& fieldset': {
      borderColor: '#98B6F1',
    },
    '&:hover fieldset': {
      borderColor: '#98B6F1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#98B6F1',
    },
  },
});

const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: '24px',
  '&.MuiOutlinedInput-root fieldset': {
    borderColor: '#98B6F1',
  },
  '&.MuiOutlinedInput-root:hover fieldset': {
    borderColor: '#98B6F1',
  },
  '&.Mui-focused fieldset': {
    borderColor: '#98B6F1',
  },
});

const SubmitButton = styled(Button)({
  '&.MuiButtonBase-root': {
    backgroundColor: '#009100',
    padding: '11px 16px',
    borderRadius: '24px',

    '&.Mui-disabled': {
      color: '#e7e7e7',
      backgroundColor: '#c9c8c8',
    },
  },
});

export default AddVisitButton;
