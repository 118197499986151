import { doPost } from '@app/api';
import { IVisit } from '@app/interfaces/visit.interface';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';

export const sendCountedSms = async (visit: IVisit) => {
  try {
    const result = await doPost(`/api/visit/${visit.slug}/counted-sms`, {});

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
