import React from 'react';
import { Box, Typography } from '@mui/material';
import { PageTitleProps } from '@app/components/pageTitle/pageTitle.props';

function PageTitle(pageTitleProps: PageTitleProps) {
  const { title, action } = pageTitleProps;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography variant='h4'>{title}</Typography>
      {action}
    </Box>
  );
}

export default PageTitle;
