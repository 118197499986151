import React from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from '@app/constants/common';
import AdminLayout from '@app/layouts/admin/AdminLayout';
import AdminLoginLayout from '@app/layouts/adminLogin/AdminLoginLayout';
import VisitsPage from '@app/pages/visitsPage/VisitsPage';
import LoginPage from '@app/pages/loginPage/LoginPage';
import HomePage from '@app/pages/homePage/HomePage';

const OPERATOR_ROUTES = [{ path: AppRoute.VISITS, component: VisitsPage }];

export function OperatorRoutes() {
  return OPERATOR_ROUTES.map(({ path, component: Component }) => (
    <Route key={path} path={path} exact>
      <AdminLayout>
        <Component />
      </AdminLayout>
    </Route>
  ));
}

export function AdminLoginRoute() {
  return (
    <Route path={AppRoute.LOGIN} exact>
      <AdminLoginLayout>
        <LoginPage />
      </AdminLoginLayout>
    </Route>
  );
}

export function HomeRoute() {
  return (
    <Route path={AppRoute.HOME} exact>
      <HomePage />
    </Route>
  );
}
