import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { doGet } from '@app/api';

export const fetchDoctorsByClinic = async (clinicId: number) => {
  try {
    const result = await doGet(`/api/doctor`, {
      params: {
        clinicId,
      },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
