import { makeAutoObservable } from 'mobx';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@app/helpers/localStorageHelper';
import {
  API_RESPONSE_STATUS_CODE,
  APP_LOCALSTORAGE_JWT_KEY,
  USER_ROLE,
} from '@app/constants/common';
import type { ILoggedUser } from '@app/interfaces/loggedUser.interface';
import { doGet, doPost } from '@app/api';

const DEFAULT_POPUP_STATE = {
  isVisible: false,
  title: 'Подтверждение',
  subtext: '',
  confirmButtonTitle: 'Подтвердить',
  text: 'Подтвердите действие',
  onConfirm: () => {},
};

class GlobalAppStore {
  async init() {
    await this.checkToken();
    this.setIsInitApp(false);
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.init();
  }

  isInitApp = true;

  token = getLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY);

  loggedUser: ILoggedUser | null = null;

  errorText = '';

  isLoading = false;

  globalConfirmPopupState = DEFAULT_POPUP_STATE;

  async checkToken() {
    if (this.token) {
      const result = await doGet('api/admin/current', {});
      if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
        this.setLoggedUser({
          ...result.data,
          token: this.token,
        });
      }
    }
  }

  get userRole() {
    return this.loggedUser?.role;
  }

  get isAdmin() {
    return this.loggedUser && this.loggedUser.role === USER_ROLE.ADMIN;
  }

  get isOperator() {
    return this.loggedUser && this.loggedUser.role === USER_ROLE.OPERATOR;
  }

  setIsInitApp(isInitApp: boolean) {
    this.isInitApp = isInitApp;
  }

  setToken(token: string) {
    this.token = token;
    setLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY, token);
  }

  setLoggedUser(user: ILoggedUser | null) {
    this.loggedUser = user;
  }

  setErrorText(text: string) {
    this.errorText = text;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async logIn(email: string, password: string) {
    this.setIsLoading(true);
    this.setErrorText('');

    try {
      const result = await doPost('/api/admin/login', {
        email,
        password,
      });

      if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
        this.setToken(result.data.user.token);
        this.setLoggedUser(result.data.user);

        return result;
      }
    } catch (e: any) {
      console.log('e', e?.response);
      if (e?.response?.data?.message) {
        this.setErrorText(e.response.data.message);
      }
    } finally {
      this.setIsLoading(false);
    }
  }

  logOut() {
    removeLocalStorageItem(APP_LOCALSTORAGE_JWT_KEY);
    this.setToken('');
    this.setLoggedUser(null);
    this.setIsInitApp(false);
  }

  updateGlobalConfirmPopupState(state = DEFAULT_POPUP_STATE) {
    this.globalConfirmPopupState = state;
  }

  closeGlobalConfirmPopup() {
    this.globalConfirmPopupState = Object.assign(this.globalConfirmPopupState, {
      isVisible: false,
    });
  }
}

// eslint-disable-next-line
export default new GlobalAppStore();
